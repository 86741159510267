import React from 'react'
import Content from '../Content'
import BlogTag from '../BlogTag'
import { CSSTransition } from 'react-transition-group'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div className='container'>
      <div className='columns'>
        <div className='column is-3 is-offset-1'>
          <h1 className='is-size-3 has-text-weight-semibold'>
            {title}
          </h1>
        </div>
        <div className='column is-7 content'>
          <CSSTransition
            key={'article'}
            timeout={5000}
            classNames='element-appear'
            appear
            in
          >
            <div>
              { cover && <img src={cover} alt={title} />}
              <PostContent content={content} />
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <div className='taglist'>
                  {(tags && tags.length)
                    ? tags.map(tag => (
                      <BlogTag tag={tag} />
                    ))
                    : null
                  }
                </div>
              </div>
              <hr />
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  )
}

export default ArticleTemplate
